export const RouterName = {
  auth: {
    login: "/auth/login",
    signup: "",
    forgotPassword: "/auth/forgot-password",
    resetPassword: "/auth/reset-password/:token",
    maintenance: "/auth/maintenance",
  },
  home: {
    home: "/dashboards",
  },
  admins: {
    admins: "/admins",
    privileges: "/privileges",
  },
  landing_page: {
    GeneralHomePage: "/general-home-page",
    ourStory: "/our-story",
    slidershow: "/slide-show",
    languages: "/languages",
    our_client: "/our_client",
    our_team: "/our_team",
    testimonials: "/testimonials",
    app_benefits: "/app_benefits",
    impacts: "/impacts",
    stats: "/stats",
    press_blog_news: "/press_blog_news",
    maps: "/maps",
  },
  settings: {
    settings: "/settings",
    legal_information: "/legal_information",
  },
  how_it_work: {
    how_it_work: "/how_it_work",
    how_it_work_details: "/how_it_work/details/:id",
  },
  activities: {
    category: "/activities/category",
    activities: "/activities",
    activities_media: "/activities/media/:id",
    activities_flashcard: "/activities/flashcard/:id",
  },
  topics: {
    topics: "/topics",
    SubTopics: "/sub_topics/:id",
  },
  flashcard: {
    flashcard: "/flash_cards",
    flashcardDetails: "/flash_card/details/:id",
    flashcardcategory: "/flash_card/categories",
    level_of_difficulty: "/anything_else",
    age_range: "/age_range",
  },
  ContactAs: {
    ContactAs: "/contact_us",
  },
  faqs: {
    faqs: "/FAQs",
  },
  tips: {
    tips: "/tips",
  },
  users: {
    users: "/users",
  },
  admins_logs: {
    admins_logs: "/admin_logs",
  },
  notification: {
    notification: "/notification",
  },
};
